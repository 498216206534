import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Card } from './components/Card/Card';
import { Footer, Header } from '../../components';
import logo from '../../assets/icons/a2b_logo.png';
import personBanner from '../../assets/en/home/mainBanner.png';
import styles from './Home.module.scss';

export function Home() {
  const { t } = useTranslation();

  const cards = [
    {
      backgroundColor: '#F5CF3D',
      label: t('home.yellowCardButton'),
      url: '/my-first-inhaler',
      img: 'people/yellowPersonNoBg.png',
    },
    {
      backgroundColor: '#FE92BF',
      label: t('home.purpleCardButton'),
      url: '/only-reliever',
      img: 'people/purplePersonNoBg.png',
    },
    {
      backgroundColor: '#B50D24',
      label: t('home.redCardButton'),
      url: '/reliever-and-preventer',
      img: 'people/redPersonNoBg.png',
    },
    {
      backgroundColor: '#9EED79',
      label: t('home.greenCardButton'),
      url: '/something-else',
      img: 'people/greenPersonNoBg.png',
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, []);

  return (
    <div className={styles.mainPage}>
      {/* ======== HEADER ========= */}
      <Header />

      {/* ======== MAIN BANNER  ========= */}
      <div className={styles.mainBanner}>
        <div className={styles.imageBanner}>
          <img src={personBanner} loading="lazy" alt="person" />
        </div>
        <div className={styles.titleBanner}>
          <div className={styles.title}> {t('home.titleAbove')} </div>
          <img src={logo} loading="lazy" alt="air 2 breathe logo" />
          <div className={styles.title}>{t('home.titleBelow')}</div>
        </div>
      </div>

      {/* ======== NAVIGATION CARDS  ========= */}
      <div className={styles.plansContainer}>
        <div className={styles.plansTitle}>
          <h3>{t('home.cardsTitle')}</h3>
        </div>

        <div className={styles.plans}>
          <div className={styles.cardContainer}>
            {cards.map(({ url, backgroundColor, label, img }) => (
              <Card
                key={url}
                url={url}
                backgroundColor={backgroundColor}
                label={label}
                img={img}
              />
            ))}
          </div>
        </div>
      </div>

      {/* ======== FOOTER ======== */}
      <Footer />
    </div>
  );
}

export default Home;
